import { useState } from 'react';
import './WebNotiseAdmin.css';
import AddNewNotise from './handlers/AddNewNotise';

const WebNotiseAdmin = () => {
    const [addForm, setAddForm] = useState<JSX.Element | undefined>(undefined);

    function openEditeForm() {
        setAddForm(<AddNewNotise setAddForm={setAddForm} />);
    }

    return (
        <div className="web-notise-admin">
            { addForm }
            <div className="block-button-admin">
                <button className="button-admin add-new-notice" onClick={ openEditeForm }>Добавить новое объявдение</button>
                <button className="button-admin add-new-background-animation" onClick={ undefined }>Добавить новую анимацию</button>
                <button className="button-admin add-playlist-songs" onClick={ undefined }>Добавить/удалить песню</button>
            </div>
            <div className="block-button-online">
                <button className="button-admin open-online" onClick={ undefined }>Открыть объявления онлайн</button>
            </div>
        </div>
    )
}
  
export default WebNotiseAdmin;